import { currencyFormatter } from "../utils/currencyFormatter";
import { useDispatch } from "react-redux";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
const Card = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCartHandler = (product) => {
    dispatch(addtoCart(product, colorAtr, sizeAtr));
  };
  const [cartQuantity, setCartQuantity] = useState(1);
  const [colorAtr, setColorAtr] = useState(null);
  const [sizeAtr, setSizeAtr] = useState(null);

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(addToSingleCart({ ...product, sizeAtr, colorAtr, cartQuantity }));
    navigate("/checkout");
  };

  return (
    <div className=" w-full  shadow-md rounded-md overflow-hidden">
      <Link
        to={`/productdetails/${product.id}`}
        className=" img  h-[11rem] flex  justify-center items-center  overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_PRODUCTS_IMAGE_URL}/${product?.photos}`}
          alt="mehedi product"
          className="w-full h-full object-cover"
        />
      </Link>
      <div className="p-2 flex flex-col">
        <Link to={`/productdetails/${product?.id}`}>
          <div className="flex items-center justify-center h-14">
            {product.unit_price === product.after_discount &&
            product.after_discount > 0 ? (
              <div className="flex items-center justify-center">
                ৳ {product?.unit_price}
              </div>
            ) : (
              <div className="flex flex-col md:flex md:flex-row justify-between items-center text-center gap-2">
                <span className="font-semibold line-through text-[#FA5303] decoration-200">
                  ৳ {product?.unit_price}
                </span>
                <span className="">৳ {+product?.after_discount}</span>
              </div>
            )}
          </div>
          <h3 className="text-[1rem] h-[3rem]  font-normal text-[#212121] mb-2 overflow-hidden">
            {product?.name?.substring(0, 40)}
            ...
          </h3>
        </Link>

        <div className="flex flex-col text-center  gap-2 justify-center items-center text-gray-500">
          <button
            onClick={() => addToCartHandler(product)}
            className="bg-[#15673D]  text-slate-50 hover:bg-[#0a1a0e] text-white duration-300 w-full py-1 rounded-full"
          >
            Add to Cart
          </button>
          <div className="border text-center  border-[#FF2626] hover:text-slate-50 hover:bg-[#FF2626] text-[#FF2626] hover:text-white duration-300 w-full  rounded-full py-[2px] ">
            <button
              onClick={handleBuyNow}
              className="py-2 px-5 text-xs font-semibold"
            >
              অর্ডার করুন
            </button>
          </div>
          {/* to={"/productdetails/"} */}
        </div>
      </div>
    </div>
  );
};

export default Card;
