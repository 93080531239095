import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ProductImageCarasol = ({ singleProduct, thumbImage }) => {
  return (
    <div className="text-white text-[20px] w-[22rem] md:w-[30rem]  lg:w-[40rem] object-cover overflow-hidden">
      <Carousel
        showIndicators={false}
        showStatus={false}
        thumbWidth={60}
        infiniteLoop={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        className="productCarousel"
      >
        <img
          src={singleProduct}
          alt=""
          className="w-full h-full object-cover"
        />
        {thumbImage.map((image, index) => (
          <img
            key={index}
            src={`${process.env.REACT_APP_URL}/uploads/product/${image.icon}`}
            alt="lol"
            className="w-full h-full object-cover"
          />
        ))}
      </Carousel>
    </div>
  );
};

export default ProductImageCarasol;
